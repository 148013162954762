import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilAddressBook,
  cilBarChart,
  cilCalendar,
  cilHome,
  cilBook,
  cilFeaturedPlaylist,
  cilSettings,
  cilBadge,
} from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react-pro'
import { Translation } from 'react-i18next'

const _nav = {
  ADMIN: [
    {
      component: CNavItem,
      name: <Translation>{(t) => t('home')}</Translation>,
      to: '/',
      icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
    },
    {
      component: CNavGroup,
      name: 'Пользователи',
      icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Контакты',
          to: '/user',
        },
        {
          component: CNavItem,
          name: 'Сертификаты',
          to: '/certificate',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Мероприятия',
      icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Список',
          to: '/seminar',
        },
        {
          component: CNavItem,
          name: 'Участники',
          to: '/seminar/certificate',
        },
        {
          component: CNavItem,
          name: 'Планер',
          to: '/seminar/calendar',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Отчёты',
      icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Инструктор',
          to: '/report/instructor',
        },
        {
          component: CNavItem,
          name: 'Топ инструкторов',
          to: '/report/instructor/top',
        },
        {
          component: CNavItem,
          name: 'Наставник',
          to: '/report/parent',
        },
        {
          component: CNavItem,
          name: 'Скачать',
          to: '/report/download',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Заказы',
      icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Список',
          to: '/order',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Справочники',
      icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Города',
          to: '/city',
        },
        {
          component: CNavItem,
          name: 'Команды',
          to: '/team',
        },
        {
          component: CNavItem,
          name: 'Курсы',
          to: '/course',
        },
        {
          component: CNavItem,
          name: 'Инструкторы',
          to: '/instructors',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Администрирование',
      icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Переместить мастеров',
          to: '/user/master_move',
        },
        {
          component: CNavItem,
          name: 'Переместить инструкторов',
          to: '/user/instructor_move',
        },
      ],
    },
  ],
  INSTRUCTOR: [
    {
      component: CNavItem,
      name: <Translation>{(t) => t('home')}</Translation>,
      to: '/',
      icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
    },
    {
      component: CNavGroup,
      name: 'Специалисты',
      to: '/user',
      icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Контакты',
          to: '/user',
        },
        {
          component: CNavItem,
          name: 'Сертификаты',
          to: '/certificate',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Мероприятия',
      // to: '/buttons',
      icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Список',
          to: '/seminar',
        },
        {
          component: CNavItem,
          name: 'Участники',
          to: '/seminar/certificate',
        },
        {
          component: CNavItem,
          name: 'Планер',
          to: '/seminar/calendar',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Заказы',
      icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Список',
          to: '/order',
        },
      ],
    },
    {
      component: CNavItem,
      name: 'Топ инструкторов',
      icon: <CIcon icon={cilBadge} customClassName="nav-icon" />,
      to: '/report/instructor/top',
    },
  ],
  PARENT: [
    {
      component: CNavItem,
      name: <Translation>{(t) => t('home')}</Translation>,
      to: '/',
      icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      icon: <CIcon icon={cilBadge} customClassName="nav-icon" />,
      name: 'Топ инструкторов',
      to: '/report/instructor/top',
    },
    {
      component: CNavGroup,
      name: 'Специалисты',
      to: '/user',
      icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Контакты',
          to: '/user',
        },
        {
          component: CNavItem,
          name: 'Сертификаты',
          to: '/certificate',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Мероприятия',
      // to: '/buttons',
      icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Список',
          to: '/seminar',
        },
        {
          component: CNavItem,
          name: 'Участники',
          to: '/seminar/certificate',
        },
        {
          component: CNavItem,
          name: 'Планер',
          to: '/seminar/calendar',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Заказы',
      icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Список',
          to: '/order',
        },
      ],
    },
  ],
  SALES: [
    {
      component: CNavItem,
      name: <Translation>{(t) => t('home')}</Translation>,
      to: '/',
      icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
    },
    {
      component: CNavGroup,
      name: 'Специалисты',
      to: '/user',
      icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Контакты',
          to: '/user',
        },
        {
          component: CNavItem,
          name: 'Сертификаты',
          to: '/certificate',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Мероприятия',
      // to: '/buttons',
      icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Список',
          to: '/seminar',
        },
        {
          component: CNavItem,
          name: 'Участники',
          to: '/seminar/certificate',
        },
        {
          component: CNavItem,
          name: 'Планер',
          to: '/seminar/calendar',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Заказы',
      icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Список',
          to: '/order',
        },
      ],
    },
  ],
  MARKETING: [
    {
      component: CNavItem,
      name: <Translation>{(t) => t('home')}</Translation>,
      to: '/',
      icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
    },
    {
      component: CNavGroup,
      name: 'Специалисты',
      to: '/user',
      icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Контакты',
          to: '/user',
        },
        {
          component: CNavItem,
          name: 'Сертификаты',
          to: '/certificate',
        },
      ],
    },
    {
      component: CNavGroup,
      name: 'Мероприятия',
      // to: '/buttons',
      icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Список',
          to: '/seminar',
        },
        {
          component: CNavItem,
          name: 'Участники',
          to: '/seminar/certificate',
        },
        {
          component: CNavItem,
          name: 'Планер',
          to: '/seminar/calendar',
        },
      ],
    },
  ],
}

export default _nav
